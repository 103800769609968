<template>
  <div>
    <v-dialog
      v-model="showTermsAndConditions"
      persistent
      scrollable
      width="840"
    >
      <v-card>
        <v-card-title
          class="title grey lighten-2"
          primary-title
        >
          Terms & Conditions
          <v-spacer />
          <span
            v-show="termsPublishedOn"
            class="font-italic"
          >(Updated {{ termsPublishedOn | displayDateFormat }})</span>
        </v-card-title>

        <v-card-text
          class="black--text pt-5"
          style="height: 500px;"
        >
          <v-skeleton-loader
            v-if="!editorData"
            type="article@4"
          />
          <froala-view
            v-else
            v-model="editorData"
          />
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-checkbox
            v-model="checkbox"
            class="mt-5 mr-3"
          >
            <template v-slot:label>
              <small>
                I represent that I am authorized to use the NOWW system and am subject to the [Terms and Conditions].
              </small>
            </template>
          </v-checkbox>
          <v-btn
            color="tertiary"
            @click="decline"
          >
            Decline
          </v-btn>
          <v-btn
            color="success"
            :disabled="!checkbox"
            @click="accept"
          >
            Accept
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import '@/plugins/vue-froala-wysiwyg'
import moment from 'moment'
import { mapActions, mapMutations, mapGetters } from 'vuex'
import { tenantService } from '@/shared/services'
// import VueFroala from "vue-froala-wysiwyg";
// import FroalaEditor from "froala-editor";

export default {
  data: () => ({
    termsPublishedOn: null,
    editorData: null,
    checkbox: false
  }),

  computed: {
    ...mapGetters('auth', ['areTermsAcceptedLatest', 'getUserId']),
    showTermsAndConditions: {
      get () {
        return this.$store.getters['auth/areTermsAcceptedLatest']() === false
      }
    }
  },

  methods: {
    ...mapActions('auth', ['acceptTerms', 'logOut']),
    decline () {
      this.logOut()
      this.$store.commit('setGlobalSnackbar', {
        message: 'Terms & Conditions must be accepted to use the platform',
        color: 'error',
        isPageChange: true
      })
      this.$router.push({ name: 'AppLogin' })
    },
    accept () {
      this.acceptTerms({ userId: this.getUserId() })
    }
  },

  watch: {
    showTermsAndConditions: {
      immediate: true,
      handler: function (newValue, oldValue) {
        if (newValue || (newValue === undefined && oldValue)) {
          tenantService.getTermsAndConditions().then(resp => {
            if (resp.errorResponse) {
              this.$store.commit('setGlobalSnackbar', {
                message: 'Could not get Terms & Conditions',
                color: 'error'
              })
              this.logOut()
              this.$router.push({ name: 'AppLogin' })
            } else {
              this.editorData = resp.content
              this.termsPublishedOn = moment
                .utc(resp.publishedOn)
                .local()
                .format()
            }
          })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ck-editor__editable_inline {
  max-height: 200px;
}
.ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
  border: none !important;
}
.ck.ck-reset_all {
  height: 0% !important;
}
</style>
