var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", scrollable: "", width: "840" },
          model: {
            value: _vm.showTermsAndConditions,
            callback: function($$v) {
              _vm.showTermsAndConditions = $$v
            },
            expression: "showTermsAndConditions"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                {
                  staticClass: "title grey lighten-2",
                  attrs: { "primary-title": "" }
                },
                [
                  _vm._v("\n        Terms & Conditions\n        "),
                  _c("v-spacer"),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.termsPublishedOn,
                          expression: "termsPublishedOn"
                        }
                      ],
                      staticClass: "font-italic"
                    },
                    [
                      _vm._v(
                        "(Updated " +
                          _vm._s(
                            _vm._f("displayDateFormat")(_vm.termsPublishedOn)
                          ) +
                          ")"
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "v-card-text",
                {
                  staticClass: "black--text pt-5",
                  staticStyle: { height: "500px" }
                },
                [
                  !_vm.editorData
                    ? _c("v-skeleton-loader", { attrs: { type: "article@4" } })
                    : _c("froala-view", {
                        model: {
                          value: _vm.editorData,
                          callback: function($$v) {
                            _vm.editorData = $$v
                          },
                          expression: "editorData"
                        }
                      })
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-checkbox", {
                    staticClass: "mt-5 mr-3",
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function() {
                          return [
                            _c("small", [
                              _vm._v(
                                "\n              I represent that I am authorized to use the NOWW system and am subject to the [Terms and Conditions].\n            "
                              )
                            ])
                          ]
                        },
                        proxy: true
                      }
                    ]),
                    model: {
                      value: _vm.checkbox,
                      callback: function($$v) {
                        _vm.checkbox = $$v
                      },
                      expression: "checkbox"
                    }
                  }),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "tertiary" },
                      on: { click: _vm.decline }
                    },
                    [_vm._v("\n          Decline\n        ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "success", disabled: !_vm.checkbox },
                      on: { click: _vm.accept }
                    },
                    [_vm._v("\n          Accept\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }